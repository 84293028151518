<template>
  <div>
    <list-layout ref="govJournal" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit"
    >
      <template #filter>
        <journal-list-filter :filterForm="filterForm" :uploadFilter="ok" />
      </template>
      <template #item_sort="{row}">{{row.sort}}</template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import PostListFilter from "@/modules/community-admin/components/postAdmin/ListFilter";
import { getGovJournals } from "@/modules/gov/api/journal-library";
import JournalListFilter from "@/modules/commerce-journal/components/ListFilter";
export default {
  components: {JournalListFilter, PostListFilter, ListLayout},
  data() {
    return {
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        name: "", //会刊名称
        year: "", //年份
        tab: "normal", //当前筛选tab
        page_size: 15, //每页数据量
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        { label: "ID", prop: "id", minWidth: 90 },
        { label: "会刊名称", prop: "name", minWidth: 110 },
        { label: "会刊描述", prop: "desc", minWidth: 150 },
        {
          label: "年份",
          prop: "year",
          minWidth: 100,
        },
        {
          label: "内容类型",
          prop: "type_text",
          minWidth: 110,
        },
        {
          label: "图片数量",
          prop: "image_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "浏览量",
          prop: "view_count",
          minWidth: 100,
          sortable: true,
        },
        { label: "排序", prop: "sort", type: 'slot', minWidth: 100 },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
        // {
        //   label: "操作", type: "operation", width: 80, operations: [{command: 'edit', text: '查看'}]
        // }
      ]
    }
  },
  methods: {
    onEdit(row) {

    },
    getList(q) {
      return getGovJournals(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govJournal.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
